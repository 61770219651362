body {
  font-family: 'Roboto', sans-serif;
}

.app {
  text-align: center;
}

.app-header {
  background-color: #ffffff;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: black;
}

.app-link {
  color: #61dafb;
}

.download-section {
  min-height: 65vh;
  background-color: #f9dc5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.download-link {
  background-color: #ffffff;
  width: 50vmin;
  padding: 20px;
  margin: 10px;
  border-radius: 1vh;
  border-color: black;
  border-width: 1px;
  border-style: solid;
}

.download-logo {
  padding-right: 10px;
  height: 5vh;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

a:link {
  color: black;
}

a:visited {
  color: black;
}

a:hover {
  color: darkgrey;
}

a:active {
  color: black;
}

.tech-section {
  min-height: 5vh;
  background-color: #f9dc5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: black;
}

.tech-logo {
  padding-right: 10px;
  height: 2vh;
}
